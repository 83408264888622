import { createContext, useEffect, useState } from "react";
import Themes from "./Themes";

class ThemeServiceApi {
  constructor() {
    this.themeListenerHandler = null;

    const lsThemeId = localStorage.getItem('themeId');

    this.themeState = {
      theme: this.checkMode() === 'light' ? Themes.light : Themes.dark,
      mode: lsThemeId ? lsThemeId : this.checkMode(),
      browserMode: this.getBrowserMode(),
    };
  }

  _handleBrowserModeChange = (event) => {
    const newBrowserMode = event.matches ? "dark" : "light";
    
    if (newBrowserMode !== this.themeState.browserMode) {
      this.themeState = {
        ...this.themeState,
        browserMode: newBrowserMode,
      };

      this._notifyListener('browser-mode', newBrowserMode);
    }
  }

  _notifyListener = (key, value) => {
    if (this.themeListenerHandler) {
      this.themeListenerHandler(key,value);
    }
  }

  getBrowserMode = () => {
    const darkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    return darkMode ? 'dark' : 'light';
  }

  checkMode = () => {
    const lsThemeId = localStorage.getItem('themeId');
    if (!lsThemeId || lsThemeId === 'default') {
      return this.getBrowserMode();
    } else {
      return lsThemeId === 'dark' ? 'dark' : 'light';
    }
  };

  setMode = mode => {
    let newMode = 'default';

    switch (mode.toLowerCase()) {
      case 'light': 
        newMode = 'light';
        break;
      case 'dark':
        newMode = 'dark';
        break;
      default: 
        newMode = 'default';
        break;
    }

    if (newMode !== this.themeState.mode) {
      this.themeState = {
        ...this.themeState,
        mode: newMode,
        theme: newMode === 'light' ? Themes.light : Themes.dark,
      };

      localStorage.setItem('themeId', newMode);

      this._notifyListener('mode', newMode);
    }
  };

  getThemeState = () => {
    return this.themeState;
  }

  registerThemeProvider = handler => {
    this.themeListenerHandler = handler;

    if (window.matchMedia) {
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', this._handleBrowserModeChange);
    }
  };

  unregisterThemeProvider = () => {
    if (window.matchMedia) {
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', this._handleBrowserModeChange);
    }

    this.themeListenerHandler = null;
  };
}

const ThemeService = new ThemeServiceApi();

export default ThemeService;

export const ThemeContext = createContext(ThemeService.getThemeState());

export const ThemeContextProvider = ({children}) => {
  const [themeState, setThemeState] = useState(ThemeService.getThemeState());

  useEffect(() => {
    const modeListener = (k,v) => {
      const ts = ThemeService.getThemeState();

      console.log("Mode changed to: " + ts.mode);

      setThemeState(ts);
    };

    ThemeService.registerThemeProvider(modeListener);

    return () => {
      ThemeService.unregisterThemeProvider();
    };
  }, []);

  return (
    <ThemeContext.Provider value={themeState}>
      {children}
    </ThemeContext.Provider>
  )
};