import { useContext } from "react";
import Assets from "../comps/assets";
import MainLayout from "../comps/main-layout";
import { ThemeContext } from "../style/ThemeContext";



const NotFound = () => {
  const {theme} = useContext(ThemeContext);

  return (
    <MainLayout title="ACNI - Not Found" headerImage={Assets.splashBg} headerImagePosition={"75% 35%"}>
      <div className="flex-col" style={{flex:1, width:'100%', alignItems:'center', justifyContent:'center'}}>
        
        <h1 className="header-text" style={{color: theme.headerText}}>Page Not Found</h1>
        <p className="body-text" style={{color: theme.bodyText}}>Sorry, but we couldn't find the page you're looking for!</p>

        <img style={{height: '80%', maxHeight:'400px', maxWidth:'90%'}} src={Assets.notFound} alt="Pip's Church" />
      </div>

    </MainLayout>
  );
};

export default NotFound;