import { NavLink } from "react-router-dom";

import SiteNav from "../api/site-nav";

const activeStyle={
  borderBottomWidth: 1,
  borderBottomColor: '#fff',
  borderBottomStyle: 'solid',
};

const MenuDesktop = ({isTablet}) => {

  const renderMenuItem = ({type, text, icon, linkTo, end}) => {
    if (type === 'link') {
      return (
        <NavLink
          to={linkTo}
          style={({ isActive }) =>
            isActive ? activeStyle : undefined
          }
          end={end}
        >
          {text}
        </NavLink> 
      )
    } else if (type === 'space') {
      return (null);
    } else if (type === 'external-icon') {
      const IconComp = icon;
      return (
        <a target="_blank" rel="noreferrer" href={linkTo}>
          <IconComp  />
        </a> 
      );
    } else {
      return null;
    }
  };

  return (
    <nav className="menu-desktop">
      <ul>
        {SiteNav.menu.map((item, index) => {
          let liClass = "";
          if (item.type === 'space') {
            liClass = 'flex-one';
          } else if (item.type === 'external-icon') {
            liClass = 'icon';
          }
          
          if (isTablet) {
            liClass += ' condensed';
          }
          return (
            <li key={index} className={liClass}>
              {renderMenuItem(item)}
            </li>
          )
        })}
      </ul>
    </nav>
  );
};

export default MenuDesktop;
