import { useContext } from "react";
import { LayoutContext } from "../style/LayoutContext";


const TwoColContent = ({col1, col2, alternate}) => {
  const {layout} = useContext(LayoutContext);

  if (layout === 'mobile') {
    return (
      <div className='mobile-two-col'>
        <div className='flex-col' style={{paddingBottom:20}}>
          {col1}
        </div>
        <div className='flex-col'>
          {col2}
        </div>
      </div>
    );
  } else {
    return (
      <div className='desktop-two-col'>
        <div className='flex-col' style={{flex: (alternate !== true) ? 1 : 2}}>
          {(alternate !== true) ? col1 : col2}
        </div>
        <div style={{width: 20}}></div>
        <div className='flex-col' style={{flex: (alternate !== true) ? 2 : 1}}>
          {(alternate !== true) ? col2 : col1}
        </div>
      </div>
    );
  }
};

export default TwoColContent;