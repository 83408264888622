import { useContext, useEffect, useState } from "react";
import EpisodesService from "../api/episodes";
import Assets from "../comps/assets";
import EpisodeCard from "../comps/episode-card";
import MainLayout from "../comps/main-layout";
import { ThemeContext } from "../style/ThemeContext";


const Episodes = () => {
  const [loading, setLoading] = useState(false);
  const [episodes, setEpisodes] = useState(null);
  const [show, setShow] = useState(null);
  const [loadingError, setLoadingError] = useState(false);

  const {theme} = useContext(ThemeContext);

  useEffect(() => {
    loadEpisodes();
  }, []);

  const loadEpisodes = async () => {
    setLoading(true);
    setLoadingError(false);

    try {

      const show = EpisodesService.getShows()[0];
      setShow(show);

      const eps = await EpisodesService.getEpisodes(show.id);

      setEpisodes(eps);
    } catch(e) {
      console.error("Error loading episodes.", e);
      setLoadingError(true);
    } finally {
      setLoading(false);
    }
  }

  let epCards = null;
  if(episodes) {
    const top100 = episodes.items.slice(0, 1000);
    
    const mediaList = episodes.mediaURLs;

    epCards = top100.map((item, index) => {
      return (
        <EpisodeCard key={index}
          show={show}
          episodeData={item}
          mediaList={mediaList}
        />
      );
    });

  }

  return (
    <MainLayout title="ACNI - Episodes" headerImage={Assets.splashBg} headerImagePosition={"75% 35%"}>
      <h1 className="header-text" style={{color:theme.headerText}}>Episodes</h1>

      {epCards}

      {loadingError === true && (
        <p className="body-text" style={{color:theme.bodyText}}>
          There was an error loading the RSS feed. Please try again later, or you can find us in your
          podcast app of choice. 
        </p>
      )}
    </MainLayout>
  )
};

export default Episodes;