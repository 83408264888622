import { useContext } from "react";
import {Link} from "react-router-dom";
import { ThemeContext } from "../style/ThemeContext";

const EpisodeCard = ({show, episodeData, mediaList}) => {

  let link = "/episodes/";
  if (show && episodeData) {
    link = `/episodes/${show.id}/${episodeData.id}`;
  }

  const {theme} = useContext(ThemeContext);

  const imgUrl = episodeData.imageIndex ? mediaList[episodeData.imageIndex] : mediaList[0];

  const date = episodeData.published ? new Date(episodeData.published).toDateString() : "";

  return (
    <Link className="episode-card" style={{color:theme.bodyText}} to={link} >
      <div>
        <img src={imgUrl} alt="episode art" />
      </div>
      <div className="episode-card-text">
        <div className="flex-row" style={{width:'100%', alignItems:'flex-start'}}>
          <span className="episode-title">{episodeData.title}</span>
          <div className="flex-column flex-one" />
          <span className="episode-date">{date}</span>
        </div>
        <p>{episodeData.description}</p>
      </div>
    </Link>    
  )
};

export default EpisodeCard;
