import { useCallback, useContext, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { FaDiceD20 } from "react-icons/fa";
import { FaSun } from "react-icons/fa";
import { FaMoon } from "react-icons/fa";
import SiteNav from "../api/site-nav";
import ThemeService, { ThemeContext } from "../style/ThemeContext";
import Assets from "./assets";

const activeStyle={
  borderBottomWidth: 3,
  borderBottomColor: '#fff',
  borderBottomStyle: 'solid',
};

const MenuMobile = ({}) => {
  const [isOpen, setOpen] = useState(false);

  const {mode, theme} = useContext(ThemeContext);

  const toggleMenu = () => {
    setOpen(o => !o);
  }

  const toggleMode = useCallback((evt) => {
    evt.preventDefault();

    ThemeService.setMode(mode === 'light' ? 'dark' : 'light');
  }, [mode]);

  const renderMenuItem = ({type, icon, text, mobileText, linkTo, end}) => {
    if (type === 'link') {
      return (
        <NavLink
          to={linkTo}
          end={end}
        >
          {text}
        </NavLink> 
      )
    } else if (type === 'space') {
      return (<hr style={{width:'100%', marginTop:20, marginBottom:20}} />);
    } else if (type === 'external-icon') {
      const IconComp = icon;
      return (
        <a target="_blank" rel="noreferrer" href={linkTo}>
          <IconComp  />
          <span style={{paddingLeft:10}}>{mobileText}</span>
        </a> 
      );
    } else {
      return null;
    }
  };

  const menuClass = "menu-mobile-list " + (isOpen ? "" : "hidden");

  return (
    <nav className="menu-mobile">
      <Link to="/">
        <img src={Assets.textLogo} alt="" style={{height:50, objectFit:"contain"}} />
      </Link>
      <div className="flex-col flex-one" />
      <div className="menu-mobile-icon" onClick={toggleMenu}>
        <FaDiceD20 size="1.5em" />
      </div>
      

      <div className={menuClass}>
        <div className="menu-mobile-icon" onClick={toggleMenu}>
          <FaDiceD20 size="1.5em" />
        </div>

        <ul>
          <li>
            <hr style={{width:'100%', marginTop:20, marginBottom:20}} />
          </li>
          {SiteNav.menu.map((item, index) => {
            return (
              <li key={index}>
                {renderMenuItem(item)}
              </li>
            )
          })}

          <li>
            <hr style={{width:'100%', marginTop:20, marginBottom:20}} />
          </li>

          <li>
            <a target="_blank" rel="noreferrer" href={mode === 'dark' ? 'light-mode' : 'dark-mode'} onClick={toggleMode}>
              {mode === 'dark' ? (
                <FaSun />
              ) : (
                <FaMoon />
              )}
              <span style={{paddingLeft:10}}>{mode === 'dark' ? 'Light Mode' : 'Dark Mode'}</span>
            </a> 
          </li>
        </ul>

      </div>
    </nav>
  );
};

export default MenuMobile;
