import { XMLParser } from 'fast-xml-parser';

class RssParserApi {
  constructor () {
    
  }

  parse = async url => {
    const rssXmlText = await this._fetchRssXml(url);
    if (!rssXmlText) {
      return null;
    }

    const xml = this._parseXml(rssXmlText);
    if (!xml) {
      return null;
    }

    return this._toJSObject(xml);
  };

  _fetchRssXml = async url => {
    try {
      const fetchResult = await fetch(url);

      const resultText = await fetchResult.text();

      if (fetchResult.ok) {
        return resultText;
      } else {
        console.error("Couldn't get result from: "+url+" -- "+fetchResult.statusText+"\n"+resultText);
        return null;
      }
    } catch (e) {
      console.error("Error retrieving RSS XML from: "+url, e);
      return null;
    }
  };

  _parseXml = text => {
    try {
      const xml = new XMLParser({
        attributeNamePrefix: '',
        textNodeName: '$text',
        ignoreAttributes: false,
      });
    
      return xml.parse(text);
    } catch(e) {
      console.error("Error parsing RSS XML", e);
      return null;
    }
  };

  _toJSObject = xml => {
    let channel = xml.rss && xml.rss.channel ? xml.rss.channel : xml.feed;
    if (Array.isArray(channel)) channel = channel[0];

    const rss = {
      title: channel.title ?? '',
      description: channel.description ?? '',
      link: channel.link && channel.link.href ? channel.link.href : channel.link,
      image: channel.image ? channel.image.url : channel['itunes:image'] ? channel['itunes:image'].href : '',
      category: channel.category || [],
      items: [],
    };

    let items = channel.item || channel.entry || [];
    if (items && !Array.isArray(items)) items = [items];

    for (let i = 0; i < items.length; i++) {
      const val = items[i];
      const media = {};


      const created = val.created ? this._parseXmlDate(val.created) : null;
      const pubDate = val.pubDate ? this._parseXmlDate(val.pubDate) : null;

      const obj = {
        id: val.guid && val.guid.$t ? val.guid.$t : val.id,
        title: val.title && val.title.$text ? val.title.$text : val.title,
        description: val.summary && val.summary.$text ? val.summary.$text : val.description,
        link: val.link && val.link.href ? val.link.href : val.link,
        author: val.author && val.author.name ? val.author.name : val['dc:creator'],
        published: created ? created : pubDate ? pubDate : Date.now(),
        created: val.updated ? Date.parse(val.updated) : pubDate ? pubDate : created ? created : Date.now(),
        category: val.category || [],
        content: val.content && val.content.$text ? val.content.$text : val['content:encoded'],
        enclosures: val.enclosure ? (Array.isArray(val.enclosure) ? val.enclosure : [val.enclosure]) : [],
      };

      ['content:encoded', 'podcast:transcript', 'itunes:summary', 'itunes:author', 'itunes:explicit', 'itunes:duration', 'itunes:season', 'itunes:episode', 'itunes:episodeType', 'itunes:image'].forEach((s) => {
        if (val[s]) obj[s.replace(':', '_')] = val[s];
      });

      if (val['media:thumbnail']) {
        Object.assign(media, { thumbnail: val['media:thumbnail'] });
        obj.enclosures.push(val['media:thumbnail']);
      }

      if (val['media:content']) {
        Object.assign(media, { thumbnail: val['media:content'] });
        obj.enclosures.push(val['media:content']);
      }

      if (val['media:group']) {
        if (val['media:group']['media:title']) obj.title = val['media:group']['media:title'];

        if (val['media:group']['media:description']) obj.description = val['media:group']['media:description'];

        if (val['media:group']['media:thumbnail']) obj.enclosures.push(val['media:group']['media:thumbnail'].url);
      }

      Object.assign(obj, { media });

      rss.items.push(obj);
    }

    return rss;
  };

  _parseXmlDate = str => {
    if (str && str.length > 0) {
      const cleanStr = str.replace("&#43;", "+");
      return Date.parse(cleanStr);
    }
    else {
      return null;
    }
  };
};

const RssParser = new RssParserApi();
export default RssParser;