import { useMediaQuery } from 'react-responsive'
import MenuDesktop from './menu-desktop';
import MenuMobile from './menu-mobile';

import Constants from '../api/constants';
import { useContext, useEffect } from 'react';
import { ThemeContext } from '../style/ThemeContext';
import Assets from './assets';
import Footer from './footer';

const MainLayout = ({
  title,
  headerImage,
  headerMinHeight,
  headerImagePosition,
  children,
}) => {
  const {theme} = useContext(ThemeContext);

  useEffect(() => {
    document.title = title;
  }, [title]);

  const headerStyle = {};
  const imgStyle = {zIndex:1};

  if (headerMinHeight) {
    headerStyle.minHeight = headerMinHeight;
  }

  if (headerImagePosition) {
    imgStyle.objectPosition = headerImagePosition;
  }

  const minDesktopOrLaptop = useMediaQuery({query: `(min-width: ${Constants.desktopBreakpoint}px)`});
  const minTablet = useMediaQuery({query: `(min-width: ${Constants.tabletBreakpoint}px`});

  const layoutType = minDesktopOrLaptop ? 'desktop' : minTablet ? 'tablet' : 'mobile';

  const isMobile = layoutType === 'mobile';
  const isTablet = layoutType === 'tablet';
  //const isDesktop = layoutType === 'desktop';

  const menu = minTablet ? <MenuDesktop isTablet={isTablet} /> : <MenuMobile />;

  return (
    <div className='main-layout'>
      <div className='header-background' style={headerStyle}>
        {headerImage && (
          <img style={imgStyle} src={headerImage} crossOrigin="anonymous" className='header-image' alt="" />
        )}
        <div style={{zIndex:2}} className={'content-column-'+layoutType}>
          {menu}
        </div>
      </div>
      <div className='content-background' style={{backgroundColor: theme.background}}>
        <div className={'content-column-'+layoutType} style={{backgroundColor: theme.background, paddingTop: 20, paddingBottom: 50}}>
          {children}
        </div>
      </div>
      <div className='footer-background'>
        <img style={{zIndex:1}} src={Assets.footerBg} crossOrigin="anonymous" className='footer-image' alt="" />
        <div style={{zIndex:2}} className={'content-column-'+layoutType}>
          <Footer isMobile={isMobile} isTablet={isTablet}/>
        </div>
      </div>

    </div>
  );
};

export default MainLayout;
