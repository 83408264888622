import Colors from "./Colors";

const Themes = {
  light: {
    bodyText: Colors.eerieBlack,
    headerText: Colors.eerieBlack,
    menuText: Colors.ghostWhite,
    background: Colors.ghostWhite,
    accent1: Colors.umber,
    accent2: Colors.darkSlateGray,
    accent3: Colors.feldgrau,
  },
  dark: {
    bodyText: Colors.ghostWhite,
    headerText: Colors.ghostWhite,
    menuText: Colors.ghostWhite,
    background: Colors.eerieBlack,
    accent1: Colors.umber,
    accent2: Colors.darkSlateGray,
    accent3: Colors.feldgrau,
  },
};

export default Themes;