import { useContext } from "react";
import Assets from "../comps/assets";
import MainLayout from "../comps/main-layout";
import TwoColContent from "../comps/two-col-content";
import { ThemeContext } from "../style/ThemeContext";
import { FaPeace } from 'react-icons/fa';


const About = () => {
  const {theme} = useContext(ThemeContext);

  const textStyle = {color: theme.bodyText};
  
  return (
    <MainLayout title="ACNI - About" headerImage={Assets.splashBg} headerImagePosition={"75% 80%"} >
      <h1 className="header-text" style={{color:theme.headerText}}>About Us</h1>

      <p className="body-text" style={{color:theme.bodyText}}>
      All Charisma, No Int is a D&D actual play podcast created by a group of friends who love playing charismatic characters doing questionable things.
      Art, design, and music for our shows are created by our very talented cast, unless noted otherwise.
      </p>

      <div className="flex-col body-text" style={{maxWidth: 900, alignSelf:'center'}}>
        <TwoColContent 
          col1={<img className="cast-pic" src={Assets.anaPic} alt="Ana Abara" />}
          col2={
            <span style={textStyle}>
              <span style={{fontWeight:'bold'}}>Ana Abara </span> 
              is an illustrator and creative who enjoys bringing new ideas to life. 
              She has been playing D&D and other TTRPGs for 13 years while DMing for roughly half that time.
              She loves video games, cuddling with her corgi, and thinking of new ways to destroy her players emotionally.
            </span>
          }
        />
        <TwoColContent 
          col1={<img className="cast-pic" src={Assets.ericPic} alt="Eric" />}
          col2={
            <span style={textStyle}>
              <span style={{fontWeight:'bold'}}>Eric </span>
              is a curious eclectic and supply chain engineer.
              He became interested in tabletop RPGs after a fortuitous visit to a local game store in 2018.
              Since then, he has been an avid player of D&D and a periodic DM.
              Eric enjoys crafting characters that blend wholesome, melodramatic, and comedic elements.
              Although he denies playing favorites among fantasy archetypes, everyone knows that he reserves a special place in his heart for druids. 
            </span>
          }
          alternate={true}
        />
        <TwoColContent 
          col1={<img className="cast-pic" src={Assets.kylePic} alt="Kyle Sweeney" />}
          col2={
            <span style={textStyle}><span style={{fontWeight:'bold'}}>Kyle Sweeney </span> 
              is a software engineer and music producer. 
              He has been playing D&D for 8 years and has experienced both sides of the DM screen. 
              Despite being an introvert in real life, Kyle likes to play high-charisma characters with a propensity for complication and drama. 
              When asked for his thoughts on roleplaying sadness and tragedy, he simply said, "Fuck me up, fam."
            </span>
          }
        />
        <TwoColContent 
          col1={<img className="cast-pic" src={Assets.lexxiePic} alt="Lexxie" />}
          col2={
            <span style={textStyle}>
              <span style={{fontWeight:'bold'}}>Lexxie </span> 
              is the newest D&D player in the group, with only a year of experience.
              Both a player and recently a DM, Lexxie enjoys the storytelling aspect of D&D the most and has been labeled the ‘writer of the most fucked-up backstories’ by Ana. 
              Outside of D&D, Lexxie does a lot of concept and illustrative art, listens to music almost all of the time, and plays a lot of video games. 
              As far as character preference goes, they usually play a more soulful, reserved type.
            </span>
          }
          alternate={true}
        />
      </div>

      <hr />

      <div className="flex-col" style={{}}>

        <h2 className="header-text" style={{color:theme.bodyText}}>Copyright and Fan Content</h2>

        <p className="body-text" style={{color:theme.bodyText}}>
          We love fan art! The easiest way to make sure we see it is to post it to the discord 
          ($2/month Patreon subscription required) or the subreddit (completely free). We also look at social media, 
          so don't forget to tag us. We are currently unable to accept anything physical, but we'd love to see a picture of the cool thing 
          you made. And if you happen to see us at a convention, feel free to come by and show us in person.
        </p>

        <p className="body-text" style={{color:theme.bodyText}}>
          If you decide to make something involving characters, places, or ideas from the podcast or other content we make, we 
          just ask that you please respect the ownership of our work. Our content is our livelihood, and we reserve all legal rights to
          protect it, but if you follow these general guidelines, you'll be fine.
        </p>

        <ul className="body-text" style={{color:theme.bodyText}}>
          <li>We own the thing we made.
            <ul>
              <li>You may not sell anything produced with our designs, art, music, creative concepts, or other intellectual property. The one exception is for one-of-a-kind physical pieces (see below).</li>
              <li>You may not mass-produce things like t-shirts or other merchandise-like items that use content from our show, whether they are sold or not. We promise merchandise will be coming, and it will be sold in an official store.</li>
              <li>You may not misrepresent our designs, art, music, creative concepts, or intellectual property as your own, for any reason.</li>
            </ul>
          </li>
          <li>You own the thing you made, but with a few caveats.
            <ul>
              <li>If you send it to us, we will assume it's okay to show the rest of the world with an attribution.</li>
              <li>Your work may be used on a stream or video that we monetize, but we will never sell your work or reproduce it outside of the obvious context of fan submissions.</li>
              <li>If you don't want us to show it off, let us know, and we'll honor your request!</li>
              <li>If we would like to use your work in another context (a charity auction or something to that effect), we will reach out to you, and we will never use it without your explicit permission.</li>
              <li>If it's a one-of-a-kind piece that is not easily reproducible, such as a physical painting or other hand-made physical item, you can do whatever you want with it (including selling it). Just make it unique and don't copy directly from our art or designs.</li>
              <li>The "one-of-a-kind piece" exception does <span style={{fontWeight:'bold', textDecorationLine:'underline'}}>NOT</span> apply to NFTs. Don't make NFTs containing our IP.</li>
              <li>You may use your fan-made content for the purpose of promoting yourself or your business.</li>
            </ul>
          </li>
          <li>Transformative Works
            <ul>
              <li>You may use our freely available recorded audio or video content as a part of a reaction, compilation, or other transformative work. We just ask that you point people to the podcast or the official source of the content.</li>
              <li>You may <span style={{fontWeight:'bold', textDecorationLine:'underline'}}>NOT</span> use our audio or video content if we have not made it freely available. So, you can use content from our free shows and promotional clips that we have made public, but any paid content (Patreon) is off-limits.</li>
              <li>You may not re-post or re-upload any of our content to any platform without recontextualizing or transforming it in a way that falls under the umbrella of "fair use."</li>
              <li>In short, do something creative that adds to the original work. Don't just repost our stuff.</li>
              <li>If you follow these rules, you may monetize your transformative work on platforms like YouTube and TikTok.</li>
            </ul>
          </li>
          <li>We are artists too, and we want to be flexible. When in doubt, reach out to us!</li>
          <li>We love to see what you're working on. Send us a link!</li>
        </ul>

        <hr />

        <h2 className="header-text" style={{color:theme.bodyText}}>Community Guidelines</h2>

        <p className="body-text" style={{color:theme.bodyText}}>
          Any digital or physical spaces that we set up and run for the community are subject to our community guidelines.
        </p>
        <p className="body-text" style={{color:theme.bodyText}}>
          We are driven to create an inclusive and safe community for all of our audience members. Bigotry and harassment
          of any kind will not be tolerated, and our moderators will use every tool at our disposal to remove bad actors from our community.
        </p>
        <p className="body-text" style={{color:theme.bodyText}}>
          While our stories might sometimes contain objectionable behavior, this is something we, as players and DM, have consented to within
          the confines of our fictional show. Our characters are not models for how we expect our community members to act 
          in real life. Treat your fellow human beings with love and respect. <FaPeace />
        </p>
      </div>
    </MainLayout>
  )
};

export default About;