import { FaSpotify } from "react-icons/fa";
import { FaApple } from "react-icons/fa";
import { FaRss } from "react-icons/fa";
import { SiGooglepodcasts } from "react-icons/si";
import { FaDiscord } from "react-icons/fa";

const acniRSS = "https://feeds.redcircle.com/291730e0-9ffe-4e8c-994c-2e13babdd9d6";
const acniMain = "https://redcircle.com/shows/allchanoint";
const acniSpotify = "https://open.spotify.com/show/2kCEgdGGwkVHmZQksDyQRz";
const acniApple = "https://podcasts.apple.com/us/podcast/all-charisma-no-int/id1686957367";
const acniGoogle = "https://www.google.com/podcasts?feed=aHR0cHM6Ly9mZWVkcy5yZWRjaXJjbGUuY29tLzI5MTczMGUwLTlmZmUtNGU4Yy05OTRjLTJlMTNiYWJkZDlkNg%3D%3D";
const acniTwitter = "https://twitter.com/AllCHANoINT";
const acniInstagram = "https://www.instagram.com/allchanoint/";
const acniTikTok = "https://www.tiktok.com/@allchanoint";
const acniReddit = "https://www.reddit.com/r/AllCHANoINT/";
const acniPatreon = "https://www.patreon.com/allchanoint";
const acniDiscord = "https://discord.gg/K4ppWhusHK";

const SiteNav = {
  links: {
    rss:acniRSS,
    redCircle:acniMain,
    spotify:acniSpotify,
    apple:acniApple,
    google:acniGoogle,
    twitter:acniTwitter,
    instagram:acniInstagram,
    tiktok:acniTikTok,
    reddit:acniReddit,
    patreon:acniPatreon,
    discord:acniDiscord,
  },
  menu: [
    {type: 'link', text: 'Home', linkTo: '/', end:true},
    {type: 'link', text: 'Episodes', linkTo: '/episodes'},
    {type: 'link', text: 'Patreon', linkTo: '/patreon'},
    {type: 'link', text: 'About', linkTo: '/about'},
    {type: 'space'},
    {type: 'external-icon', mobileText: 'Discord Server', icon: FaDiscord, linkTo: acniDiscord},
    {type: 'external-icon', mobileText: 'Apple Podcasts', icon: FaApple, linkTo: acniApple},
    {type: 'external-icon', mobileText: 'Spotify', icon: FaSpotify, linkTo: acniSpotify},
    {type: 'external-icon', mobileText: 'Google Podcasts', icon: SiGooglepodcasts, linkTo: acniGoogle},
    {type: 'external-icon', mobileText: 'RSS', icon: FaRss, linkTo: acniRSS},
  ],
};

export default SiteNav;
