import Cache from "./cache";
import RssParser from "./rss-parser";

const handleHtmlDescription = (str) => {
  var newStr = str.replace(/<\/\s*[pP]\s*>/g, '\n');
  newStr = newStr.replace(/<[^p]*p[^>]*>/g, '');
  newStr = newStr.replace(/<br[^>]*>/g, '');
  return newStr;
};

class EpisodesApi {
  constructor() {
    this.episodeCache = new Cache({
      cacheKey:"episodes", 
      onFetchData: this._getAllEpisodes,
      cacheLocal: false,                //cache in user's browser
      maxCacheTimeSeconds: (60*60),     //cache for 1 hour
    });

    //this.episodeCache.invalidateCache();
  }

  _getAllEpisodes = async key => {
    const shows = this.getShows();
    const show = shows.find(s => s.id === key);

    if(!show || !show.rss) {
      console.error("Error finding RSS feed for key: "+key);
      return {
        success: false,
        message: "Could not find show."
      }
    }

    console.log("Refreshing episodes.");

    try {
      const data = await RssParser.parse(show.rss);

      const mediaURLs = [];

      if (data.image) {
        mediaURLs.push(data.image);
      }

      const itemsLength = data.items.length;

      const cd = {
        title: data.title,
        description: handleHtmlDescription(data.description),
        link: data.link,
        imageIndex: mediaURLs.length > 0 ? 0 : -1,
        mediaURLs: mediaURLs,
        items: data.items.map((i, index) => {
          const title = '' + (i.title ? i.title : (itemsLength - index));
          const item = {
            id: title.replace(/[^a-zA-Z0-9]/g,'-'),
            title: title,
            description: handleHtmlDescription(i.description),
            link: i.link,
            published: i.published,
          };

          if (i.itunes_image && i.itunes_image.href) {
            let imgInd = mediaURLs.findIndex(m => m === i.itunes_image.href);
            
            if (imgInd < 0) {
              mediaURLs.push(i.itunes_image.href);
              imgInd = mediaURLs.length-1;
            }

            item.imageIndex = imgInd;
          }

          return item;
        }),
      }

      console.log("Cache updated with "+cd.items.length+" episodes");

      return {
        success: true,
        message: '',
        result: cd,
      };

    } catch (e) {
      console.error("Error loading RSS feed: "+show.rss, e);
      return {
        success: false,
        message: 'Error loading RSS feed'
      }
    }
  };

  getShows = () => {
    return [
      {
        name: "All Charisma, No Int",
        id: "acni",
        rss: "https://feeds.redcircle.com/291730e0-9ffe-4e8c-994c-2e13babdd9d6",
      },
    ];
  };

  getEpisodes = async (showId) => {
    const result = await this.episodeCache.getItem(showId);

    return result;
  };

  getSeasons = () => {
    return [
      {
        name: "Ruined S1",
        id: "ruined-s1",
      },
      {
        name: "Finding the Crit",
        id: "ftc",
      },
    ];
  }
};

const EpisodesService = new EpisodesApi();

export default EpisodesService;
