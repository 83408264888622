const MAX_CACHE_TIME_MEM = 3600; //1 hour
const MAX_CACHE_TIME_LOCAL = 2592000; //30 days

export default class Cache {
  constructor({ cacheKey, onFetchData, cacheLocal, maxCacheTimeSeconds }) {
    this.onFetchData = onFetchData; // (itemKey) => {success:<boolean>, result:<object>}
    this.cacheLocal = cacheLocal === true;
    this.cacheKey = this.cacheLocal ? 'cache-' + cacheKey + '-' : '';
    this.maxCacheTimeSeconds = maxCacheTimeSeconds
      ? maxCacheTimeSeconds
      : this.cacheLocal
      ? MAX_CACHE_TIME_MEM
      : MAX_CACHE_TIME_LOCAL;

    this.fetching = {};
    this.memCache = {};

    if (this.cacheLocal) {
      const allKeys = Object.keys(window.localStorage);

      for (let i = 0; i < allKeys.length; i++) {
        const k = allKeys[i];
        if (k.indexOf(this.cacheKey) === 0) {
          try {
            const cacheStr = window.localStorage.getItem(k);
            if (cacheStr) {
              const cacheObj = JSON.parse(cacheStr);
              this.memCache[k] = cacheObj;
            }
          } catch (e) {
            console.log('Error loading cached object with key: ' + k, e);
          }
        }
      }
    }
  }

  invalidateCache = () => {
    this.memCache = {};
    try {
      if (this.cacheLocal) {
        const allKeys = Object.keys(window.localStorage);

        for (let i = 0; i < allKeys.length; i++) {
          const k = allKeys[i];
          if (k.indexOf(this.cacheKey) === 0) {
            console.log('Invalidating cache key: ' + k);
            window.localStorage.removeItem(k);
          }
        }
      }
    } catch (e) {
      console.log('Error invalidating keys for cache: ' + this.cacheKey);
    }
  };

  invalidateItem = itemKey => {
    const key = this.cacheKey + itemKey;
    if (this.cacheLocal) {
      window.localStorage.removeItem(key);
    }
    delete this.memCache[key];
  };

  getItem = async itemKey => {
    const key = this.cacheKey + itemKey;

    //Detect if we are currently fetching the data
    const fetching = this.fetching[itemKey];
    if (fetching) {
      //console.log("  "+this.cacheKey+itemKey+" - currently fetching.");
      return await fetching;
    }

    //Check cached data
    let cacheItem = this.memCache[key];
    const now = Date.now();

    if (cacheItem && now - cacheItem.lastUpdated < this.maxCacheTimeSeconds * 1000) {
      return cacheItem.data;
    } else {
      this.invalidateItem(itemKey);
    }

    //console.log("  "+this.cacheKey+itemKey+" - not in cache -- fetching.");
    //If we get here, it means we don't have a valid cached item, so fetch the most recent version
    const fetchItemPromise = this.onFetchData(itemKey)
      .then(async result => {
        //console.log("  "+this.cacheKey+itemKey+" - Fetched object -- "+JSON.stringify(result));

        //result of onFetchData expects an object containing a success field, and the result. Only cache if it was successful. Always return the result.
        if (result.success) {
          const cacheData = {
            data: result.result ? result.result : null,
            lastUpdated: now,
          };
          this.memCache[key] = cacheData;

          if (this.cacheLocal) {
            const cacheDataStr = JSON.stringify(cacheData);
            window.localStorage.setItem(key, cacheDataStr);
          }
        }

        delete this.fetching[itemKey];

        //console.log("  "+this.cacheKey+itemKey+" - item fetched. Storing in cache: "+result.success);
        return result.result;
      })
      .catch(e => {
        console.log('Error fetching cache data for key ' + key, e);
        return null;
      });

    this.fetching[itemKey] = fetchItemPromise;

    const fetchResult = await fetchItemPromise;

    //console.log("  "+this.cacheKey+itemKey+" - returning fetched item. ", JSON.stringify(fetchResult));
    return fetchResult;
  };
}
