
const Assets = {
  logo512: require('../images/logo512.png'),
  textLogo: require('../images/TextLogoSmall.png'),
  splashBg: require('../images/header.jpg'),
  footerBg: require('../images/footer.jpg'),
  renePic: require('../images/rene-bio-2.jpg'),
  twoPic: require('../images/two-bio.jpg'),
  riverPic: require('../images/river-bio-2.jpg'),
  dmPic: require('../images/dm-bio.jpg'),
  anaPic: require('../images/char-bio2.jpg'),
  ericPic: require('../images/char-bio2.jpg'),
  kylePic: require('../images/char-bio2.jpg'),
  lexxiePic: require('../images/char-bio2.jpg'),
  //kylePic: require('../images/kyle-bio.jpg'),
  //lexxiePic: require('../images/lexxie-bio.jpg'),
  notFound: require('../images/not-found.jpg'),
}

export default Assets;
