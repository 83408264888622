import { useContext, useEffect, useState } from "react";
import MainLayout from "../comps/main-layout";
import { ThemeContext } from "../style/ThemeContext";
import EpisodesService from "../api/episodes";
import Assets from "../comps/assets";
import seedrandom from "seedrandom";


const staticAssignments = {
  'ruined-s1': [
    {episode: 0, contentWarning: 'Eric', outro: 'Lexxie'},
    {episode: 1, contentWarning: 'Ana', outro: 'Kyle'},
    {episode: 2, contentWarning: 'Kyle', outro: 'Eric'},
    {episode: 3, contentWarning: 'Lexxie', outro: 'Ana'},
    {episode: 4, contentWarning: 'Eric', outro: 'Lexxie'},
    {episode: 5, contentWarning: 'Kyle', outro: 'Ana'},
    {episode: 6, contentWarning: 'Ana', outro: 'Eric'},
    {episode: 7, contentWarning: 'Lexxie', outro: 'Kyle'},
    {episode: 8, contentWarning: 'Eric', outro: 'Lexxie'},
    {episode: 9, contentWarning: 'Kyle', outro: 'Eric'},
    {episode: 10, contentWarning: 'Ana', outro: 'Kyle'},
    {episode: 11, contentWarning: 'Lexxie', outro: 'Ana'},
  ],
  'ftc': [
    {episode: 0, contentWarning: 'Lexxie', outro: 'None'},
    {episode: 1, contentWarning: 'Ana', outro: 'None'},
  ],
}

const names = ['Ana', 'Eric', 'Kyle', 'Lexxie'];

const randomNames = (rndFunc) => {
  const l = names.length;
  const newNames = names.slice();
  for (let i = 0; i < l; i++) {
    let swapIndex = Math.floor(rndFunc() * l);
    let swapName = newNames[swapIndex];
    newNames[swapIndex] = newNames[i];
    newNames[i] = swapName;
  }
  
  return newNames;
}

const randomSet = (rndFunc, previousSet) => {
  const prevSetL = previousSet ? previousSet.length : -1;
  const lastItemPrevSet = prevSetL > 0 ? previousSet[prevSetL-1] : null;
  
  let n1 = null;

  while (n1 === null) {
    n1 = randomNames(rndFunc);
    
    //Make sure we don't have 2 of the same person in a row for the content warning
    if (lastItemPrevSet && n1[0] === lastItemPrevSet.name1) {
      console.log("name1 same as prev" + n1[0] + "===" + lastItemPrevSet.name1);
      n1 = null;
    } else {
      console.log("name1 not same as prev" + n1[0] + "!==" + (lastItemPrevSet ? lastItemPrevSet.name1 : '---'));
    }
  }
  
  const l = n1.length;
  let n2 = null;

  while (n2 === null) {
    n2 = randomNames(rndFunc);

    //make sure we don't have 2 of the same person in a row for the outro
    if (lastItemPrevSet && n2[0] === lastItemPrevSet.name2) {
      n2 = null;
    }

    //Check to make sure it's not the same person doing both parts
    if (n2) {
      for (let i = 0; i < l; i++) {
        if (n1[i] === n2[i]) {
          n2 = null;
          break;
        }
      }
    }

    //Check to make sure it's not just 2 sets of pairs
    if (n2) {
      const first4Names = [n1[0], n2[0], n1[1], n2[1]];
      const uniqueNames = {};
      first4Names.forEach(n => {
        uniqueNames[n] = n;
      });

      if (Object.keys(uniqueNames).length !== 3) {
        n2 = null;
      }
    }
  }

  const pairs = n1.map((name1, ind) => {
    return {
      name1: name1,
      name2: n2[ind],
    }
  });

  return pairs;
}

const Assignments = () => {
  const [season, setSeason] = useState(null);
  const [assignments, setAssignments] = useState(null);

  const {theme} = useContext(ThemeContext);

  useEffect(() => {
    setSeason(EpisodesService.getSeasons()[0].id);
  }, []);

  useEffect(() => {
    const staticEps = staticAssignments[season];
    if (!staticEps) {
      return ;
    }

    const allAssignments = staticEps.slice();

    const startEp = staticEps[staticEps.length - 1];
    const startEpNum = startEp.episode;
    const pr = seedrandom(season+startEpNum);

    const l = names.length;

    let prevSet = [{
      name1: startEp.contentWarning,
      name2: startEp.outro,
    }];

    console.log("Starting prev set: "+JSON.stringify(prevSet));
    
    for (let i = 0; i < 25; i++) {
      const rndSet = randomSet(pr, prevSet);

      rndSet.forEach((set, setInd) => {
        allAssignments.push({
          episode: startEpNum + i * l + setInd + 1,
          contentWarning: set.name1,
          outro: season === 'ftc' ? 'none' : set.name2,
        });
      });

      prevSet = rndSet;
    }

    setAssignments(allAssignments);

  }, [season]);

  let asgnCards = null;
  if(assignments && assignments.length > 0) {
    asgnCards = assignments.map((a,i) => {
      return (
        <tr key={a.episode} className="body-text" style={{color:theme.bodyText}}>
          <td>{a.episode}</td>
          <td>{a.contentWarning}</td>
          <td>{a.outro}</td>
        </tr>
      );
    });
  }

  let seasonOpts = EpisodesService.getSeasons().map((s, i) => {
    return (
      <option key={s.id} value={s.id}>{s.name}</option>
    );
  });
  

  return (
    <MainLayout title="ACNI - Assignments" headerImage={Assets.splashBg} headerImagePosition={"75% 35%"}>
      <h1 className="header-text" style={{color:theme.headerText}}>Season</h1>

      <select value={season ? season : ''} onChange={e => setSeason(e.target.value) }>
        {seasonOpts}
      </select>

      <table>
        <thead className="header-text" style={{color:theme.headerText}}>
          <tr>
            <th style={{paddingRight:20}}>Episode</th>
            <th style={{paddingRight:20}}>Content Warning</th>
            <th style={{paddingRight:20}}>Outro</th>
          </tr>
        </thead>
        <tbody className="body-text" style={{color:theme.bodyText}}>
          {asgnCards}
        </tbody>
      </table>
    </MainLayout>
  )
};

export default Assignments;