import { useCallback, useContext } from "react";

import { FaDiscord, FaSun } from "react-icons/fa";
import { FaMoon } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaReddit } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

import SiteNav from "../api/site-nav";

import ThemeService, { ThemeContext } from "../style/ThemeContext";
import Colors from "../style/Colors";

const Footer = ({isMobile, isTablet}) => {
  const {mode} = useContext(ThemeContext);

  const toggleMode = useCallback((evt) => {
    evt.preventDefault();

    ThemeService.setMode(mode === 'light' ? 'dark' : 'light');
  }, [mode]);

  let containerClass = 'footer-main-layout';
  let w1 = '50%';
  let w2 = '25%';
  if (isTablet) {
    w1 = '60%';
    w2 = '40%';
  }
  if (isMobile) {
    containerClass = 'footer-mobile-layout';
    w1 = '100%';
    w2 = '100%';
  } 

  return (
    <div className={'footer-content ' + containerClass}>
      
      <div className="flex-col" style={{width: w1}}>
        <h2 className='body-text' style={{color:Colors.ghostWhite, marginBottom: 0}}>Subscribe and add +2 to Charisma</h2>
        
        <p className='body-text' style={{color:Colors.ghostWhite, maxWidth: 500}}>Your information will only be used to send you updates about the podcast and special events.</p>

        <div className='signup-form'>
          <button id="signupButton">
            <span>Subscribe!</span>          
          </button>
        </div>
        
        <p></p>
      </div>

      <div className="flex-col body-text" style={{width: w2, color:Colors.ghostWhite}}>
        <h2>Socials</h2>
        <div style={{marginBottom:5}}>
          <a target="_blank" rel="noreferrer" href={SiteNav.links.twitter}>
            <FaTwitter  />
            <span style={{paddingLeft:10}}>Twitter</span>
          </a> 
        </div>
        <div style={{marginBottom:5}}>
          <a target="_blank" rel="noreferrer" href={SiteNav.links.instagram}>
            <FaInstagram />
            <span style={{paddingLeft:10}}>Instagram</span>
          </a> 
        </div>
        <div style={{marginBottom:5}}>
          <a target="_blank" rel="noreferrer" href={SiteNav.links.tiktok}>
            <FaTiktok  />
            <span style={{paddingLeft:10}}>TikTok</span>
          </a>   
        </div>
        <div style={{marginBottom:5}}>
          <a target="_blank" rel="noreferrer" href={SiteNav.links.reddit}>
            <FaReddit  />
            <span style={{paddingLeft:10}}>Reddit</span>
          </a> 
        </div>
        <div style={{marginBottom:5}}>
          <a target="_blank" rel="noreferrer" href={SiteNav.links.discord}>
            <FaDiscord  />
            <span style={{paddingLeft:10}}>Discord</span>
          </a> 
        </div>
      </div>

      <div className="flex-col body-text" style={{width: w2, color:Colors.ghostWhite}}>
        <h2>
          <a target="_blank" rel="noreferrer" href="mailto: contact@allchanoint.com">
            <span>Contact Us</span>
          </a> 
        </h2>

        <h2>
          <a target="_blank" rel="noreferrer" href={mode === 'dark' ? 'light-mode' : 'dark-mode'} onClick={toggleMode}>
            {mode === 'dark' ? (
              <>
                <span style={{paddingRight:10}}>Light Mode</span>
                <FaSun />
              </>
            ) : (
              <>
                <span style={{paddingRight:10}}>Dark Mode</span>
                <FaMoon />
              </>
            )}
          </a> 
        </h2>
      </div>

      <div className='flex-col body-text' style={{width: '100%', color:Colors.ghostWhite}}>
        <p>© 2023-Present - All Charisma, No Int</p>
      </div>
    </div>
  );
};

export default Footer;
