import { createContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Constants from "../api/constants";

export const LayoutContext = createContext({layout: 'desktop'});

export const LayoutContextProvider = ({children}) => {
  const [layoutState, setLayoutState] = useState({layout: 'desktop'});

  const isDesktopOrLaptop = useMediaQuery({query: `(min-width: ${Constants.desktopBreakpoint}px)`});
  const isTablet = useMediaQuery({query: `(min-width: ${Constants.tabletBreakpoint}px`});

  useEffect(() => {
    const layoutType = isDesktopOrLaptop ? 'desktop' : isTablet ? 'tablet' : 'mobile';

    setLayoutState(oldls => {
      if (oldls.layout !== layoutType) {
        return {
          ...oldls,
          layout: layoutType
        };
      } else {
        return oldls;
      }
    });

  }, [isDesktopOrLaptop, isTablet])

  return (
    <LayoutContext.Provider value={layoutState}>
      {children}
    </LayoutContext.Provider>
  )
};