import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import EpisodesService from "../api/episodes";
import Assets from "../comps/assets";
import MainLayout from "../comps/main-layout";
import { ThemeContext } from "../style/ThemeContext";
import TwoColContent from "../comps/two-col-content";

import { FaSpotify } from "react-icons/fa";
import { FaApple } from "react-icons/fa";
import { FaRss } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { SiGooglepodcasts } from "react-icons/si";
import SiteNav from "../api/site-nav";


const Episode = () => {
  const [loading, setLoading] = useState(false);
  const [episodes, setEpisodes] = useState(null);
  
  const [prevId, setPrevId] = useState(null);
  const [nextId, setNextId] = useState(null);

  const {sId, epId} = useParams();

  const {theme, mode} = useContext(ThemeContext);

  const loadEpisodes = useCallback(async (seasonId) => {
    setLoading(true);

    try {
      const show = EpisodesService.getShows().find(s => s.id === seasonId);

      if (!show) {
        return;
      }

      const eps = await EpisodesService.getEpisodes(show.id);

      setEpisodes(eps);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadEpisodes(sId);
  }, [sId, loadEpisodes]);

  useEffect(() => {
    if (episodes?.items && epId) {
      const items = episodes.items;
      const thisInd = items.findIndex(ep => ep.id === epId);
      
      if (thisInd > 0) {
        setNextId(items[thisInd-1].id);
      } else {
        setNextId(null)
      }

      if (thisInd < items.length-1) {
        setPrevId(items[thisInd+1].id);
      } else {
        setPrevId(null)
      }
    }
  }, [episodes, epId]);

  let title = "Episode";
  let episodeDetails = null;
  if (episodes) {
    const mediaList = episodes.mediaURLs;

    const episode = episodes.items.find(e => e.id === epId);

    if (episode) {
      const imgUrl = episode.imageIndex ? mediaList[episode.imageIndex] : mediaList[0];

      const date = episode.published ? new Date(episode.published).toDateString() : "";

      title = episode.title;

      let iconsClass = "episode-ext-icons-light";
      if (mode === "dark") {
        iconsClass = "episode-ext-icons-dark";
      }

      episodeDetails = (
        <TwoColContent 
          col1={<img className="episode-pic" src={imgUrl} alt="Episode" />}
          col2={
            <div className="flex-col body-text" style={{color:theme.bodyText}}>
              <h2 style={{fontWeight:'bold', marginBlock: 0}}>{title}</h2>
              <span className="episode-date">{date}</span>
              {
                episode.description.split("\n").map((str, pInd) => {
                  if (str.trim().length > 0) {
                    return (
                      <p key={pInd} style={{marginBottom:0}}>{str}</p>
                    )
                  } else {
                    return null;
                  }
                })
              }
              <h2 style={{fontWeight:'bold', marginBlock: 0, marginTop:40, marginBottom:20}}>Listen On</h2>
              <div className={"flex-row  body-text "+iconsClass} style={{justifyContent:'space-evenly'}}>
                <a className="link-button" target="_blank" rel="noreferrer" href={SiteNav.links.spotify}>
                  <FaSpotify style={{marginRight:5}} /><span>Spotify</span>
                </a> 
                <a className="link-button" target="_blank" rel="noreferrer" href={SiteNav.links.apple}>
                  <FaApple style={{marginRight:5}} /><span>Apple Podcasts</span>
                </a> 
                <a className="link-button" target="_blank" rel="noreferrer" href={SiteNav.links.google}>
                  <SiGooglepodcasts style={{marginRight:5}} /><span>Google Podcasts</span>
                </a> 
                <a className="link-button" target="_blank" rel="noreferrer" href={SiteNav.links.rss}>
                  <FaRss style={{marginRight:5}} /><span>RSS</span>
                </a> 
              </div>
              <div className={"body-text"} style={{alignItems:'center', fontSize: '1.25em', marginTop:30}}>
                ...or wherever you listen to podcasts.
              </div>
            </div>
          }
          alternate={false}
        />
      );
    }
  } 
  
  if (!loading && !episodeDetails) {
    episodeDetails = (
      <>
        <h1 className="header-text" style={{color: theme.headerText}}>Not Found</h1>
        <p className="body-text" style={{color: theme.bodyText}}>Sorry, but we couldn't find this episode!</p>
      </>
    )
  }

  return (
    <MainLayout title={'ACNI - ' + title} headerImage={Assets.splashBg} headerImagePosition={"75% 35%"}>
      {episodeDetails}

      <div className="flex-row" style={{flexGrow:1}}></div>

      <div className="episode-nav" style={{marginTop:30, fontSize: '1.5em', color: theme.bodyText, width:'100%', maxWidth:800, alignSelf:'center'}}>
        <div className="flex-row flex-one" style={{alignItems:'center', justifyContent:'center'}}>
          {prevId && (
            <Link style={{color:theme.bodyText}} to={`/episodes/${sId}/${prevId}`}><FaArrowLeft style={{marginRight:5}} /></Link>
          )}
        </div>

        <div className="flex-row flex-one" style={{alignItems:'center', justifyContent:'center'}}>
          <Link style={{color:theme.bodyText}} to="/episodes/"><span>All Episodes</span></Link>
        </div>

        <div className="flex-row flex-one" style={{alignItems:'center', justifyContent:'center'}}>
          {nextId && (
            <Link style={{color:theme.bodyText}} to={`/episodes/${sId}/${nextId}`}><FaArrowRight style={{marginRight:5}} /></Link>
          )}
        </div>
      </div>
    </MainLayout>
  )
};

export default Episode;