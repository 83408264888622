import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import './App.css';

//import ComingSoon from './pages/coming-soon';
import Home from './pages/home';
import Episodes from './pages/episodes';
import Episode from './pages/episode';
import Assignments from './pages/assignments';
import About from './pages/about';
import Patreon from "./pages/patreon";
import NotFound from "./pages/not-found";
import { ThemeContextProvider } from "./style/ThemeContext";
import { LayoutContextProvider } from "./style/LayoutContext";
//import GoogleAnalytics from "./comps/google-analytics";


const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      {/*<Route path="/"                     element={<ComingSoon />} />*/}
      {/*<Route path="/home"                 element={<Home />} />*/}
      <Route path="/"                     element={<Home />} />
      <Route path="/episodes"             element={<Episodes />} />
      <Route path="/episodes/:sId/:epId"  element={<Episode />} />
      <Route path="/patreon"              element={<Patreon />} />
      <Route path="/about"                element={<About />} />
      <Route path="/assignments"          element={<Assignments />} />
      <Route path="*"                     element={<NotFound />} />
    </>
  )
);


function App() {
  return (
    <LayoutContextProvider>
      <ThemeContextProvider>
        <RouterProvider router={router} >
          {/*<GoogleAnalytics />*/}
        </RouterProvider>
      </ThemeContextProvider>
    </LayoutContextProvider>
  );
}

export default App;
