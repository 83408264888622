import { useContext } from "react";
import Assets from "../comps/assets";
import MainLayout from "../comps/main-layout";
import { ThemeContext } from "../style/ThemeContext";

import { FaPatreon } from "react-icons/fa";
import Colors from "../style/Colors";
import SiteNav from "../api/site-nav";

const Patreon = () => {

  const {theme} = useContext(ThemeContext);

  const join = tierName => {

  };

  const hrStyle= {
    marginTop: 30,
    marginBottom: 30,
  };

  return (
    <MainLayout title="ACNI - Patreon" headerImage={Assets.splashBg} headerImagePosition={"75% 60%"}>
      <h1 className="header-text" style={{color:theme.headerText}}>Patreon</h1>

      <p className="body-text" style={{color:theme.bodyText}}>
        All Charisma, No Int is supported by listeners like you. 
        If you would like additional perks and content, please consider supporting us on Patreon. 
        We have tiers for all budgets, and you can join for less than the cost of a sandwich. 
      </p>

      <p className="body-text" style={{color:theme.bodyText}}>
        We have big plans for more stuff, and as we grow, your subscription will get you more and more. Any level of
        support is greatly appreciated!
      </p>


      <h2 className="header-text" style={{color:theme.headerText}}>Initiate's Slider<span className="body-text">: $2/month</span></h2>

      <p className="body-text" style={{color:theme.bodyText}}>
        Come slide into our Discord server and join a community of like-minded fans. Talk about the show and 
        anything else you might be passionate about. There are also meet-and-greets and other events
        with the creators, and members will be the first to get the scoop on news and announcements. In addition, you 
        will get access to our early show drop on Monday instead of Wednesday.
      </p>

      <p className="body-text" style={{color:theme.bodyText, marginBottom: 0}}>
        What You Get:
      </p>
      
      <ul className="body-text" style={{color:theme.bodyText}}>
        <li>Gain access to special channels in the Discord Server.</li>
        <li>Get the show 2 days early. Monday instead of Wednesday.</li>
      </ul>

      <a className="patreon-link" target="_blank" rel="noreferrer" href={SiteNav.links.patreon}>
        <button 
          className="body-text"
          style={{borderColor: theme.bodyText, backgroundColor:theme.accent2, color:Colors.ghostWhite}}
        >
          <span>Initiate's Slider: $2</span>
          <FaPatreon />
        </button>
      </a>

      <hr style={hrStyle} />

      <h2 className="header-text" style={{color:theme.headerText}}>Adventurer's Club<span className="body-text">: $5/month</span></h2>

      <p className="body-text" style={{color:theme.bodyText}}>
        Join the club, adventurers! Gather round and listen to our monthly post-show podcast, Finding the Crit, where we answer questions
        about the characters and episodes and other TTRPG topics. You will also receive exclusive monthly artwork from our very talented artists.
        Got an idea for a homebrew item or a name for an NPC? Send them our way, and they may find their way into the show. And lastly, you will
        receive an RSS feed for completely ad-free episodes.
      </p>

      <p className="body-text" style={{color:theme.bodyText, marginBottom: 0}}>
        What You Get:
      </p>
      
      <ul className="body-text" style={{color:theme.bodyText}}>
        <li>Everything from the Initiate's Slider Tier.</li>
        <li>Access to the after-show, Finding the Crit.</li>
        <li>Exclusive Art.</li>
        <li>Submit an idea for an item or NPC name.</li>
        <li>Ad-free episodes.</li>
      </ul>

      <a className="patreon-link" target="_blank" rel="noreferrer" href={SiteNav.links.patreon}>
        <button 
          className="body-text"
          style={{borderColor: theme.bodyText, backgroundColor:theme.accent2, color:Colors.ghostWhite}}
          onClick={() => join('tier2')}
        >
          <span>Adventurer's Club: $5</span>
          <FaPatreon />
        </button>
      </a>

      <hr style={hrStyle} />

      <h2 className="header-text" style={{color:theme.headerText}}>Hero's Gyro<span className="body-text">: $10/month</span></h2>

      <p className="body-text" style={{color:theme.bodyText}}>
        Be the hero Ilune deserves! Help us achieve our goal of financial independence so we can start
        creating even more cool stuff. Believe us, we have lots of plans, but we can only do them justice if 
        we can give them our full, undivided attention. If you decide to support us at this tier, you will
        receive our undying love and have your name shouted out at the end of the episodes, but you will also be
        paving the way for more goodies from us down the road.
      </p>

      <p className="body-text" style={{color:theme.bodyText, marginBottom: 0}}>
        What You Get:
      </p>

      <ul className="body-text" style={{color:theme.bodyText}}>
        <li>Everything from the Initiate's Slider Tier and the Adventurer's Club Tier.</li>
        <li>Your name in the credits.</li>
        <li>Our endless appreciation!</li>
      </ul>

      <a className="patreon-link" target="_blank" rel="noreferrer" href={SiteNav.links.patreon}>
        <button 
          type="button"
          className="pabody-text"
          style={{borderColor: theme.bodyText, backgroundColor:theme.accent2, color:Colors.ghostWhite}}
          onClick={() => join('tier3')}
        >
          <span>Hero's Gyro: $10</span>
          <FaPatreon />
        </button>
      </a>

      <hr style={hrStyle} />

      <p className="body-text" style={{color:theme.bodyText}}>
        We know that not everyone has the means to pay for entertainment, so our main show will always remain free.
        Leaving a review and telling your friends about us is a tangible way to help at no cost and is greatly appreciated. 
        No matter your situation, we are endlessly grateful for everyone who listens and supports the show. Thank you!
      </p>

    </MainLayout>
  )
};

export default Patreon;