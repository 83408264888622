import { useContext, useEffect, useState } from "react";
import EpisodesService from "../api/episodes";

import {Link} from "react-router-dom";

import ActivityIndicator from "../comps/activity-indicator";
import MainLayout from "../comps/main-layout";
import EpisodeCard from "../comps/episode-card";
import Assets from "../comps/assets";
import { ThemeContext } from "../style/ThemeContext";
import TwoColContent from "../comps/two-col-content";

const show = EpisodesService.getShows()[0];

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [episodes, setEpisodes] = useState(null);

  const {theme} = useContext(ThemeContext);

  useEffect(() => {
    loadEpisodes();
  }, []);

  const loadEpisodes = async () => {

    if (!show) {
      console.error("Trying to load episodes, but there is no show selected.");
      return;
    }

    setLoading(true);
    try {
      const eps = await EpisodesService.getEpisodes(show.id);

      setEpisodes(eps);
    } finally {
      setLoading(false);
    }
  }

  let epCards = null;
  if(show && episodes) {
    const top3 = episodes.items.slice(0,3);
    
    const mediaList = episodes.mediaURLs;

    epCards = top3.map((item, index) => {
      return (
        <EpisodeCard key={index}
          episodeData={item}
          mediaList={mediaList}
          show={show}
        />
      );
    });

  }

  return (
    <MainLayout title="All Charisma, No Int" headerImage={Assets.splashBg} headerMinHeight={"60%"} headerImagePosition={"75% 50%"}>
      {loading && (<ActivityIndicator size={20} />)}

      <div className="flex-col" style={{maxWidth: 900, alignSelf: 'center'}}>

        <h1 className="header-text" style={{color:theme.bodyText}}>Ruined</h1>

        <p className="body-text" style={{color:theme.bodyText}}>
          Enter Ilune (/ˈɪluːn/), a mysterious world of magic and secrets where vibrant cultures have sprung up out of 
          the ruins of an ancient civilization.
          Join us as we roleplay: being hot, eating sandwiches, sewing, talking to every animal, murders, brooding,
          being mysterious, dying, and occasionally delving into the ruins of that very same ancient civilization.
        </p>
        <p className="body-text" style={{color:theme.bodyText}}>
          <span style={{fontWeight:'bold'}}>Ruined</span> is the first season of <span style={{fontWeight:'bold'}}>All Charisma, No Int</span>, 
          a D&D actual play podcast for beautiful nerds like you.
        </p>

        <hr />

        <h2 className="header-text" style={{color:theme.bodyText}}>Meet the Characters</h2>
      
        <TwoColContent 
          col1={<img className="cast-pic" src={Assets.renePic} alt="René Julián Vincenzo LeBlanc" />}
          col2={
            <div className="flex-col body-text" style={{color:theme.bodyText}}>
              <h3 style={{fontWeight:'bold', marginBlock: 0}}>René Julián Vincenzo LeBlanc <span style={{fontStyle: 'italic', fontWeight:'normal'}}>(He/Him)</span></h3>
              <span style={{fontWeight:'bold'}}>Air Genasi - Rogue / Warlock</span>
              <span style={{fontWeight:'bold'}}>Played by Eric <span style={{fontStyle: 'italic', fontWeight:'normal'}}>(He/Him)</span></span>
              <p>
                Painfully handsome, misguidedly optimistic, and selectively superstitious, René is a gentlemanly man-about-town.
                His life was changed forever after a series of increasingly improbable misadventures in his homeland.
                With a new mindset and pressing goals, René set out for the promise of good fortune in Xeldova.
                In a sudden stroke of serendipity, he became the co-owner of a ruin delving enterprise with his new friend River.
                While René takes little interest in business, he considers it bad luck to squander a gift from the gods, 
                so he does his best while honing his skills as an amateur tailor and chef. 
              </p>
            </div>
          }
        />
        <TwoColContent 
          col1={<img className="cast-pic" src={Assets.twoPic} alt="Wild" />}
          col2={
            <div className="flex-col body-text" style={{color:theme.bodyText}}>
              <h3 style={{fontWeight:'bold', marginBlock: 0}}>Wild <span style={{fontStyle: 'italic', fontWeight:'normal'}}>(He/Him - They/Them)</span></h3>
              <span style={{fontWeight:'bold'}}>Aasimar - Cleric</span>
              <span style={{fontWeight:'bold'}}>Played by Lexxie  <span style={{fontStyle: 'italic', fontWeight:'normal'}}>(They/Them)</span></span>
              <p>
                The Wilder One, better known as "Wild" or "Two," is a man of mystery. 
                Coming from a faraway place, they appear to have customs and traditions unfamiliar to their companions. 
                He joined The Lucky Gentleman’s Ruin Delves Club seemingly for no more than a place to work, even though so far it has not been very lucrative. 
                They still remain, seemingly unbothered by the slow income. 
                Worshiping The Keeper, Two holds a value in blood and death that others do not always seem to see.
              </p>
            </div>
          }
          alternate={true}
        />
        <TwoColContent 
          col1={<img className="cast-pic" src={Assets.riverPic} alt="River of Stars" />}
          col2={
            <div className="flex-col body-text" style={{color:theme.bodyText}}>
              <h3 style={{fontWeight:'bold', marginBlock: 0}}>River of Stars <span style={{fontStyle: 'italic', fontWeight:'normal'}}>(He/Him)</span></h3>
              <span style={{fontWeight:'bold'}}>Astral Elf - Bard / Warlock</span>
              <span style={{fontWeight:'bold'}}>Played by Kyle Sweeney <span style={{fontStyle: 'italic', fontWeight:'normal'}}>(He/Him)</span></span>
              <p>
                River is perhaps the most literal interpretation of the podcast's name. The hottest idiot imaginable. 
                After experiencing an early childhood trauma, he wandered the wilderness for a while with only animals as companions.
                Eventually, he made his way back into society and was granted entrance into "orphan school", where he learned to play music
                and paint. He joined an adventuring company to help pay for his art supplies, at which point the original owner died a tragic death 
                and ownership transferred to River and René via some loophole in Xeldovan law.
              </p>
            </div>
          }
        />
        <TwoColContent 
          col1={<img className="cast-pic" src={Assets.dmPic} alt="Everyone Else" />}
          col2={
            <div className="flex-col body-text" style={{color:theme.bodyText}}>
              <h3 style={{fontWeight:'bold', marginBlock: 0}}>The Dungeon Master</h3>
              <span style={{fontWeight:'bold'}}>Played by Ana Abara  <span style={{fontStyle: 'italic', fontWeight:'normal'}}>(She/Her)</span></span>
              <p>
                The world of Ilune is an ever-shifting realm of mystery that is carefully guided by our DM Ana Abara.
                With detailed scenery, overly opinionated animals, complicated lunch orders, and a revolving door of colorful NPCs, 
                there is no shortage of information unrelated to any plot for our heroes to hyperfixate on.
              </p>
            </div>
          }
          alternate={true}
        />
        
      </div>

      <hr />
      
      <div className="flex-col" style={{alignSelf: 'center'}}>
        <h1 className="header-text" style={{color:theme.headerText}}>Latest Episodes</h1>
        {epCards}

        <Link to="/episodes" style={{color:theme.bodyText}}><span style={{fontSize:"1.25em"}}>View All Episodes</span></Link>
      </div>

    </MainLayout>
  )
};

export default Home;